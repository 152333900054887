import {MonkeyAlgorithm} from '../../src/Algorithms/MonkeyAlgorithm.mjs';
import {EagleAlgorithm} from '../../src/Algorithms/EagleAlgorithm.mjs';

class App
{
    constructor()
    {
        console.log('Hi developer');
    }

    init()
    {
        // Register Double score switch handler
        const doubleScoreSwitch = document.querySelector('#double-score');
        doubleScoreSwitch.addEventListener('change', (event) => this.doubleScoreHandler(event), false);

        // Register Generate form Handler
        const generateForm = document.querySelector('#generate-form');
        generateForm.addEventListener('submit', (event) => this.generateFormHandler(event), false);

        // Register Copy to clipboard button handler for top results
        const resultsClipboardButton = document.querySelector('.copy-results-to-clipboard');
        resultsClipboardButton.addEventListener('click', (event) => this.copyResultsToClipboardHandler(event), false);

        // Register Share on WhatsApp button handler for top results
        const resultsWhatsAppButton = document.querySelector('.share-results-on-whatsapp');
        resultsWhatsAppButton.addEventListener('click', (event) => this.shareResultsOnWhatsAppHandler(event), false);

        // Register Copy to clipboard button handler for a single result (event delegated)
        const results = document.querySelector('#results');
        results.addEventListener('click', (event) => this.copyResultToClipboardHandler(event), false);

        // Register Share on WhatsApp button handler for a single result (event delegated)
        results.addEventListener('click', (event) => this.shareResultOnWhatsAppHandler(event), false);
    }

    doubleScoreHandler(event)
    {
        let doubleScoreSwitch = event.currentTarget;
        let textarea = document.querySelector('#generate-form textarea');

        if (doubleScoreSwitch.checked) {
            textarea.placeholder = 'Mike, 3, 5\nJohn, 7, 6\nSarah, 4.5, 2';
        } else {
            textarea.placeholder = 'Mike, 3\nJohn, 7\nSarah, 4.5';
        }
    }

    generateFormHandler(event)
    {
        // Prevent submission
        event.preventDefault();
        event.stopPropagation();

        // Clear previous errors and results
        event.target.querySelectorAll('.is-invalid').forEach((el) => el.classList.remove('is-invalid'));
        event.target.querySelector('.invalid-feedback').innerText = '';
        document.querySelector("#results").classList.add('d-none');
        document.querySelector("#results-output").replaceChildren();

        // Parse and validate form data
        try {
            let formData = new FormData(event.target);
            var doubleScore = formData.get('double-score') ? true: false;
            var players = formData.get('players').trim().split('\n');
            players.forEach((player, index) => {
                player = player.trim().split(',');

                // Validate structure/length of player data
                let dataLength = (doubleScore) ? 3 : 2;
                if (player.length != dataLength) {
                    throw new Error('The structure of the data is incorrect!');
                }

                // Validate player name (not empty)
                let playerName = player[0].trim();
                if (playerName.length === 0) {
                    throw new Error('A given player name is empty!');
                }

                // Validate player score (not empty and float)
                let playerScores = [];
                for (var i = 1; i < dataLength; i++) {
                    playerScores[i-1] = parseFloat(new Number(player[i].trim()));
                    if (isNaN(playerScores[i-1]) || !isFinite(playerScores[i-1])) {
                        throw new Error('A given player score is incorrect!');
                    }
                }

                // Build player object
                if (!doubleScore) player = { "name": playerName, "rating": playerScores[0] };
                else player = { "name": playerName, "rating": { "attack": playerScores[0], "defense": playerScores[1] } };

                players[index] = player;
            });
        } catch(e) {
            event.target.querySelector('#players').classList.add('is-invalid');
            event.target.querySelector('#players ~ .invalid-feedback').innerText = e.message;
            return;
        }

        // Run algorithm
        if (!doubleScore) { var matchups = MonkeyAlgorithm.getMatchups(players); }
        else { var matchups = EagleAlgorithm.getMatchups(players); }

        // Print matchups
        const limitOutput = 0;
        for (let key in matchups) {
            const matchup = matchups[key];

            // Print matchup results on interface
            const matchupTemplate = document.querySelector("#results-template");
            const matchupFragmentClone = matchupTemplate.content.cloneNode(true);
            const matchupElement = matchupFragmentClone.firstElementChild;

            matchupElement.querySelector(".result-title").textContent = "Option " + String.fromCharCode(65 + parseInt(key));

            if (!doubleScore) {
                matchupElement.querySelector(".result-bal").textContent = "± " + matchup.imbalance;
                if (matchup.imbalance == 0) { matchupElement.querySelector(".result-bal").classList.add('bg-success-subtle', 'border-success-subtle', 'text-success-emphasis'); }
                else { matchupElement.querySelector(".result-bal").classList.add('bg-warning-subtle', 'border-warning-subtle', 'text-warning-emphasis'); }

                matchupElement.querySelector(".result-team1-avg").textContent = matchup.team0.rating;
                matchupElement.querySelector(".result-team2-avg").textContent = matchup.team1.rating;
            } else {
                matchupElement.querySelector(".result-bal").textContent = "± " + matchup.imbalance.overall + "";
                if (matchup.imbalance.global == 0) { matchupElement.querySelector(".result-bal").classList.add('bg-success-subtle', 'border-success-subtle', 'text-success-emphasis'); }
                else { matchupElement.querySelector(".result-bal").classList.add('bg-warning-subtle', 'border-warning-subtle', 'text-warning-emphasis'); }

                matchupElement.querySelector(".result-team1-avg").textContent = matchup.team0.rating.overall + " (" + matchup.team0.rating.attack + ", " + matchup.team0.rating.defense + ")";
                matchupElement.querySelector(".result-team2-avg").textContent = matchup.team1.rating.overall + " (" + matchup.team1.rating.attack + ", " + matchup.team1.rating.defense + ")";
            }

            const members0 = matchupElement.querySelector(".result-team1-members");
            matchup.team0.players.forEach((player) => {
                members0.appendChild(document.createElement("li")).textContent = player.name;
            });

            const members1 = matchupElement.querySelector(".result-team2-members");
            matchup.team1.players.forEach((player) => {
                members1.appendChild(document.createElement("li")).textContent = player.name;
            });

            document.querySelector("#results-output").appendChild(matchupElement);

            // Store matchup data on element
            matchupElement._dataMatchup = matchup;

            // Print matchup results on console
            if (!doubleScore) {
                console.log("\n" + "Option " + String.fromCharCode(65 + parseInt(key)) + ", " + "(Imbalance " + matchup.imbalance + "):");
                console.log("Team 1: " + matchup.team0.players.map(player => player.name).join(', ') + " -> (" + matchup.team0.rating + ")");
                console.log("Team 2: " + matchup.team1.players.map(player => player.name).join(', ') + " -> (" + matchup.team1.rating + ")");
            } else {
                console.log("\n" + "Option " + String.fromCharCode(65 + parseInt(key)) + ", " + "(Imbalance: OA " + matchup.imbalance.overall + ", ATT " + matchup.imbalance.attack + ", DEF " + matchup.imbalance.defense + ", GLOB " + matchup.imbalance.global + "):");
                console.log("Team 1: " + matchup.team0.players.map(player => player.name).join(', ') + " -> ( OA " + matchup.team0.rating.overall + ", ATT " + matchup.team0.rating.attack + ", DEF " + matchup.team0.rating.defense + ")");
                console.log("Team 2: " + matchup.team1.players.map(player => player.name).join(', ') + " -> ( OA " + matchup.team1.rating.overall + ", ATT " + matchup.team1.rating.attack + ", DEF " + matchup.team1.rating.defense + ")");
            }

            // Limit printed matchups to a number (alphabet letters if none specified)
            if (parseInt(key) + 1 === (limitOutput || 26)) break;
        }

        // Show results and scroll to them
        let results = document.querySelector("#results");
        results.classList.remove('d-none');
        results.scrollIntoView();
    }

    copyResultsToClipboardHandler(event)
    {
        let clipboardText = '';
        let matchupsElements = document.querySelector('#results-output').children;

        for (var i = 0; i < Math.min(matchupsElements.length, 3); i++) {
            let matchupData = matchupsElements[i]._dataMatchup;

            clipboardText += "Option " + String.fromCharCode(65 + parseInt(i)) + ":\n\n";
            clipboardText += "⚪: " + matchupData.team0.players.map(player => player.name).join(', ') + "\n\n";
            clipboardText += "⚫: " + matchupData.team1.players.map(player => player.name).join(', ') + "\n\n";
        }

        console.log(clipboardText.trim());
        navigator.clipboard.writeText(clipboardText);

        event.target.closest('button').blur();
    }

    shareResultsOnWhatsAppHandler(event)
    {
        let whatsAppText = '';
        let matchupsElements = document.querySelector('#results-output').children;

        for (var i = 0; i < Math.min(matchupsElements.length, 3); i++) {
            let matchupData = matchupsElements[i]._dataMatchup;

            whatsAppText += "*Option " + String.fromCharCode(65 + parseInt(i)) + "*:\n\n";
            whatsAppText += "⚪: " + matchupData.team0.players.map(player => player.name).join(', ') + "\n\n";
            whatsAppText += "⚫: " + matchupData.team1.players.map(player => player.name).join(', ') + "\n\n";
        }

        console.log(whatsAppText.trim());
        let whatsAppUrl = `https://api.whatsapp.com/send/?text=${encodeURIComponent(whatsAppText.trim())}`;
        window.open(whatsAppUrl);

        event.target.closest('button').blur();
    }

    copyResultToClipboardHandler(event)
    {
        // Handle event delegation
        let ancestor = event.currentTarget;
        let target = event.target;

        while(target && target !== ancestor) {
            if (target.matches('.copy-result-to-clipboard')) {
                let matchupElement = target.closest('.matchup');
                let matchupData = matchupElement._dataMatchup;

                let clipboardText = "⚪: " + matchupData.team0.players.map(player => player.name).join(', ') + "\n\n";
                clipboardText += "⚫: " + matchupData.team1.players.map(player => player.name).join(', ');

                console.log(clipboardText);
                navigator.clipboard.writeText(clipboardText);

                target.blur();
                return;
            }

            target = target.parentElement;
        }
    }

    shareResultOnWhatsAppHandler(event)
    {
        // Handle event delegation
        let ancestor = event.currentTarget;
        let target = event.target;

        while(target && target !== ancestor) {
            if (target.matches('.share-result-on-whatsapp')) {
                let matchupElement = target.closest('.matchup');
                let matchupData = matchupElement._dataMatchup;

                let whatsAppText = "⚪: " + matchupData.team0.players.map(player => player.name).join(', ') + "\n\n";
                whatsAppText += "⚫: " + matchupData.team1.players.map(player => player.name).join(', ');
                console.log(whatsAppText);

                let whatsAppUrl = `https://api.whatsapp.com/send/?text=${encodeURIComponent(whatsAppText)}`;
                window.open(whatsAppUrl);

                target.blur();
                return;
            }

            target = target.parentElement;
        }
    }
}

var app = new App();
app.init();
