export class EagleAlgorithm
{
    /**
     * Get all possible matchups ordered by best balanced.
     *
     * The given players must have 'name' and 'rating' properties.
     * The ratings must have 'attack' and 'defense' values.
     *
     * @param  array        players
     * @param  mixed|null   options
     * @return mixed
     */
    static getMatchups(players, options = null)
    {
        // Build matchups
        const numberOfPlayers = players.length;
        const matchups = [];

        for (let i = 0; i < Math.pow(2, numberOfPlayers) / 2; i++) {
            // For every number we build the binary mapping
            const mapping = i.toString(2).padStart(numberOfPlayers, "0");

            // Filter matchups with a team size difference of more than one player
            const nOnes = Array.from(mapping).reduce((acc, val) => acc + parseInt(val), 0);
            const nZeros = numberOfPlayers - nOnes;

            if (Math.abs(nOnes - nZeros) <= 1) {
                // Create teams from the binary mapping
                let team0 = { players: [], rating: { attack: 0, defense: 0, overall: 0 } }
                let team1 = { players: [], rating: { attack: 0, defense: 0, overall: 0 } }

                for (let j = 0; j < numberOfPlayers; j++) {
                    const binSide = mapping[j];
                    let teamX = eval(`team${binSide}`);

                    teamX.players.push(players[j]);
                    teamX.rating.attack += players[j].rating.attack;
                    teamX.rating.defense += players[j].rating.defense;
                    teamX.rating.overall += (players[j].rating.attack + players[j].rating.defense) / 2;
                }

                // Save both teams, his global rating and the global difference (imbalance)
                matchups.push({
                    team0: team0, team1: team1,
                    imbalance: {
                        overall: Math.abs(team1.rating.overall - team0.rating.overall),
                        attack: Math.abs(team1.rating.attack - team0.rating.attack),
                        defense: Math.abs(team1.rating.defense - team0.rating.defense),
                        global: Math.abs(team1.rating.overall - team0.rating.overall) * 2 + Math.abs(team1.rating.attack - team0.rating.attack) + Math.abs(team1.rating.defense - team0.rating.defense),
                    }
                });
            }
        }

        // Sort matchups by best imbalance
        matchups.sort((a, b) => (a.imbalance.global == b.imbalance.global) ? 0 : ((a.imbalance.global < b.imbalance.global) ? -1 : 1));

        return matchups;
    }
}
