export class MonkeyAlgorithm
{
    /**
     * Get all possible matchups ordered by best balanced.
     *
     * The given players must have 'name' and 'rating' properties.
     *
     * Each retuned matchup is an object with the following properties:
     *    teamA: ['players' => [], 'rating' => float]
     *    teamB: ['players' => [], 'rating' => float]
     *    imbalance: float
     *
     * @param  array        players
     * @param  mixed|null   options
     * @return mixed
     */
    static getMatchups(players, options = null)
    {
        // Build matchups
        const numberOfPlayers = players.length;
        const matchups = [];

        for (let i = 0; i < Math.pow(2, numberOfPlayers) / 2; i++) {
            // For every number we build the binary mapping
            const mapping = i.toString(2).padStart(numberOfPlayers, "0");

            // Filter matchups with a team size difference of more than one player
            const nOnes = Array.from(mapping).reduce((acc, val) => acc + parseInt(val), 0);
            const nZeros = numberOfPlayers - nOnes;

            if (Math.abs(nOnes - nZeros) <= 1) {
                // Create teams from the binary mapping
                let team1 = [], team0 = [];
                let ratingTeam1 = 0, ratingTeam0 = 0;

                for (let j = 0; j < numberOfPlayers; j++) {
                    if (mapping[j] === "1") {
                        team1.push(players[j]);
                        ratingTeam1 += players[j].rating;
                    } else {
                        team0.push(players[j]);
                        ratingTeam0 += players[j].rating;
                    }
                }

                // Save both teams, his global rating and the global difference (imbalance)
                matchups.push({
                    team0: { players: team0, rating: ratingTeam0 },
                    team1: { players: team1, rating: ratingTeam1 },
                    imbalance: Math.abs(ratingTeam1 - ratingTeam0)
                });
            }
        }

        // Sort matchups by best imbalance
        matchups.sort((a, b) => (a.imbalance === b.imbalance) ? 0 : ((a.imbalance < b.imbalance) ? -1 : 1));

        return matchups;
    }
}
